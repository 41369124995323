@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

$primary-color: #F89B60;
$secondary-color :#F36D55;


@font-face {
    font-family: NunitoSans-Bold;
    src: url('../font/NunitoSans_7pt-Bold.ttf');
}

@font-face {
    font-family: NunitoSans-Semibold;
    src: url('../font/NunitoSans_7pt-SemiBold.ttf');
}

@font-face {
    font-family: NunitoSans-Medium;
    src: url('../font/NunitoSans_7pt-Medium.ttf');
}

@font-face {
    font-family: NunitoSans-Regular;
    src: url('../font/NunitoSans_7pt-Regular.ttf');
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-28 {
    font-size: 28px;
}

.fs-32 {
    font-size: 32px;
}

.fB {
    font-family: 'NunitoSans-Bold';
}

.fSB {
    font-family: 'NunitoSans-Semibold';
}

.fM {
    font-family: 'NunitoSans-Medium';
}

.fR {
    font-family: 'NunitoSans-Regular';
}

.solution-card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.bg-circle {
    width: 500px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.case_study_img {
    width: 100%;
    height: 28rem;
    object-fit: cover;
}

.slick-slide {
    height: 100% !important;
}


.home_banner {
    background-color: #000;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.navColor {
    color: rgb(146, 143, 143);
}

.nav-item {
    transition: all 0.3s ease-in-out,
}

.nav-item:hover {
    transition: all 0.3s ease-in-out,
}

.nav-item-contact-text {
    max-width: 800px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

}

.banner_h {
    background-color: #000;
    width: 100%;
    height: 80vh;
    margin-top: 5rem;
}

.service_banner_h {
    background-color: #000;
    width: 100%;
    height: 80vh;
    margin-top: 5rem;
}

.banner_title {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 700;
}

.white_banner_title {
    font-size: 3rem;
    line-height: 1.3;
    font-weight: 700;
}

.ripple {
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: coral;
    border: 2px solid #fff;
    position: relative;
    z-index: 5;

    &:before {
        content: "";
        width: 500px;
        height: 500px;
        position: absolute;
        border-radius: 50%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: -80px;
        left: -80px;
        border: 2px solid rgb(255, 255, 255);
        animation: anim-ripple 2s infinite;
        z-index: -1;
    }

    &::after {
        content: "";
        width: 460px;
        height: 460px;
        position: absolute;
        border-radius: 50%;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        top: -60px;
        left: -58px;
        border: 2px solid rgb(255, 255, 255);
        animation: anim-ripple 2s infinite;
        z-index: -1;
    }


    @keyframes anim-ripple {
        from {
            opacity: 1;
            transform: scale3d(0.75, 0.75, 1);
        }

        to {
            opacity: 0;
            transform: scale3d(1.25, 1.25, 1);
        }
    }
}

.solution-industry-banner {
    background-image: url('../images/retail-industry-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.89) inset;
    width: 100%;
}

.count-circle {
    position: absolute;
    z-index: 6;
    left: -150px;
    bottom: 40px;
}

.count_circle_carousel {
    position: absolute;
    right: 100px;
    bottom: -100px;
    color: white;
}

.banner_image {
    background-image: url('../images/company_banner.webp');
    width: 100%;
    height: 80vh;
    object-fit: cover;
    margin-top: 5rem;
}

.solution_banner {
    background-image: url('../images/solution_banner.png');
    width: 100%;
    height: 80vh;
    object-fit: cover;
    margin-top: 5rem;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0 !important;
    margin-right: auto;
}

.white_banner {
    background-image: url('../images/white_banner1.webp');
    width: 100%;
    height: 60vh;
    object-fit: cover;
    overflow: hidden;
    margin-top: 5rem;

}

.industry_banner {
    background-image: url('../images/white_banner1.webp');
    width: 100%;
    height: 60vh;
    object-fit: cover;
    overflow: hidden;
    margin-top: 1rem;

}

// .contact_banner {
//     background-image: url('../images/white_banner1.webp');
//     background-repeat: no-repeat;
//     width: 100%;
//     min-height: 80vh;
//     margin-top: 5rem;
// }
.home_banner_content {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    z-index: 10;
}

.career_banner {
    background-image: url('../images/white_banner1.webp');
    width: 100%;
    height: 60vh;
    object-fit: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-top: 5rem;
}

.contact_content_banner {
    background-image: url('../images/content_banner4.webp');
    background-attachment: fixed;
    overflow: hidden;
    position: relative;
    background-size: cover;
    height: 400px;
    display: block;
    margin: 70px 0;
    padding: 60px 0;
}

.bg_gradient_red {
    background: linear-gradient(90deg, #F36D55 0 30%, transparent);
}

.bg_gradient_orange {
    background: linear-gradient(90deg, #F89B60 0 30%, transparent);
}

.bg_gradient_gray {
    background: linear-gradient(90deg, #646E78 0 30%, transparent);
}

.bg_gradient_brown {
    background: linear-gradient(90deg, #6F1A07 0 30%, transparent);
}

.bg_gradient_blue {
    background: linear-gradient(90deg, #26557D 0 30%, transparent);
}

.line-clamp-10 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
}

.slick-next:before {
    content: url(../images/icon/Right_arrow.svg);
}

.slick-prev {
    left: -52px !important;
}

.slick-next {
    right: -52px !important;
}

.slick-slider,
.slick-slider .slick-track,
.slick-slider .slick-list {
    height: 100% !important;
}

.slick-prev:before {
    content: url(../images/icon/left_arrow.svg);
    transform: rotate(180deg);
}

.slick-prev,
.slick-next {
    position: absolute;
    top: 60%;
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    background: $primary-color !important;
    border-radius: 50%;
    z-index: 20;
    margin: 0 35px !important;
    opacity: .75;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
    color: transparent;
    outline: none;
    background: $primary-color;
}

.slick-prev:before,
.slick-next:before {
    font-size: 35px;
    font-weight: 900;
    line-height: 0.5;
    opacity: 1;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
    opacity: .25;
}

.slick-prev.slick-disabled,
.slick-next.slick-disabled {
    visibility: hidden;
}


.progress_bar {
    width: 600px;
}

.progress_bar #length {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 18%;
    background-color: #fff;
    opacity: .2;
}

.progress_bar #progress_animation {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 18%;
    background: linear-gradient(to right, #F89B60, #F36D55);
    z-index: 55;
    animation: moving;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

#progress_static_bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 18%;
    background: linear-gradient(to right, #F89B60, #F36D55);
}

#progress_completed {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    height: 18%;
    background: linear-gradient(to right, #F89B60, #F36D55);
}

.slider_container img {
    display: none;
}

.slider_container img.active {
    display: block;
}

.banner_error {
    width: 100%;
    height: 100vh;
    margin-top: 8rem;
    display: flex;
}

.error_img {
    margin: auto;
}

@keyframes moving {
    0% {
        width: 0;
    }

    // 50%{
    //     width: 50%;
    // }
    100% {
        width: 100;
    }
}

.job_btn {
    display: block;
    margin: auto;
}

// button animation //
.wrapper .arrow {
    background-image: url(../images/arrow-top-btn.png);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;

}

.wrapper .arrow:before {
    content: "";
    background-image: url(../images/arrow-top-btn.png);
    display: block;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    height: 10px;
    position: absolute;
    opacity: 0.5;
}

.wrapper .arrow:after {
    content: "";
    background-image: url(../images/arrow-top-btn.png);
    display: block;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    height: 10px;
    position: absolute;
    opacity: 0.3;
}

.wrapper button:hover .arrow:before {
    animation: animate-arrow-2 1s ease-in-out infinite 0.1s;
}

.wrapper button:hover .arrow:after {
    animation: animate-arrow-3 1s ease-in-out infinite 0.2s;
}

.wrapper button:hover .arrow {
    animation: animate-arrow-1 1s ease-in-out infinite;
}

@keyframes animate-arrow-1 {
    0% {
        transform: translateY(-15px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes animate-arrow-2 {
    0% {
        transform: translateY(-12px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 0.5;
    }
}

@keyframes animate-arrow-3 {
    0% {
        transform: translateY(-8px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 0.3;
    }
}

//button animation end //
// button white animation //
.wrapper .white-arrow {
    background-image: url(../images/white-top-arrow.png);
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: cover;

}

.wrapper .white-arrow:before {
    content: "";
    background-image: url(../images/white-top-arrow.png);
    display: block;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 10px;
    height: 10px;
    position: absolute;
    opacity: 0.5;
}

.wrapper .white-arrow:after {
    content: "";
    background-image: url(../images/white-top-arrow.png);
    display: block;
    width: 8px;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    height: 10px;
    position: absolute;
    opacity: 0.3;
}

.wrapper button:hover .white-arrow:before {
    animation: animate-arrow-2 1s ease-in-out infinite 0.1s;
}

.wrapper button:hover .white-arrow:after {
    animation: animate-arrow-3 1s ease-in-out infinite 0.2s;
}

.wrapper button:hover .white-arrow {
    animation: animate-arrow-1 1s ease-in-out infinite;
}

@keyframes animate-arrow-1 {
    0% {
        transform: translateY(-15px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes animate-arrow-2 {
    0% {
        transform: translateY(-12px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 0.5;
    }
}

@keyframes animate-arrow-3 {
    0% {
        transform: translateY(-8px);
        opacity: 0;
    }

    70%,
    100% {
        transform: translateY(0px);
        opacity: 0.3;
    }
}

//button white animation end //
.service_item {
    border-left: 2px solid #d3d3d3;
    padding: 50px 0 2px 0;

    .service_item_box {
        margin-bottom: 10px;
        padding: 10px 10px 10px 100px;
        position: relative;

        .service_item_content {
            display: flex;
            gap: 10px;
            align-items: center;
            vertical-align: middle;
        }

        .service_hover {
            padding: 10px;
            cursor: pointer !important;
        }

        .service_hover:hover {
            background-color: #f6f6f6;
            cursor: pointer !important;
            box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        }

        .service_item_content::before {
            position: absolute;
            width: 90px;
            left: 0;
            top: 20px;
            height: 2px;
            background-color: #d3d3d3;
            content: "";
            vertical-align: middle;
            align-items: center;
        }
    }
}

.time-line::after {
    content: '';
    position: absolute;
    width: 1.5px;
    background-color: #D4D4D4;
    top: 0;
    bottom: 0;
    left: 9px;
}

.development_item {

    border-left: 2px solid #d3d3d3;
    // padding: 10px 0 2px 0;
    padding-left: 10px;
    // width: calc(100%/3);

    .development_item_content {
        font-size: 18px;
        line-height: 43px;
        padding-left: 30px;
        position: relative;
    }

    .development_item_content::before {
        position: absolute;
        width: 30px;
        left: -10px;
        top: 22px;
        height: 2px;
        background-color: #d3d3d3;
        content: "";
    }

}

footer {
    .social-icon {
        width: 44px;
        height: 44px;
        background-color: #29333D;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.portfolio {

    .portfolio_card {
        height: 500px;

        .portfolio_title {
            line-height: 1.1;
        }
    }
}

// ::-webkit-scrollbar {
//     width: 10px;
// }

.menubar_scroll::-webkit-scrollbar {
    width: 1px;
}

.menubar-header {
    position: relative;
}

.menubar-header-content {
    position: absolute;
    right: 30px;
}

.transition-effect {
    transition: all 0.35;
}

.blog-hover:hover {
    background-color: rgb(246, 246, 246);
    border-radius: 5px;
}

.blog-hover-div {
    margin: 15px;
    // padding-top: 10px;
}

.blog-img {
    height: 180px;
    border-radius: 5px;
}

.blog-btn {
    border: 1px solid black;
}

.blog-text-color {
    color: #F36D55;
}

$breakpoint-tablet: 1280px;
@media (max-width: $breakpoint-tablet) {
    .banner_error {
        width: 100%;
        height: 70vh;
        margin-top: 8rem;
    }


}

$breakpoint-tablet: 1024px;
@media (max-width: $breakpoint-tablet) {
    .banner_error {
        width: 100%;
        height: 80vh;
        margin-top: 8rem;
    }

    .error_text {
        font-size: 30px;
        position: absolute;
        top: 230px;
        left: 370px;
    }

    .error_img {
        margin: auto;
    }

}

$breakpoint-tablet: 912px;
@media (max-width: $breakpoint-tablet) {
    .banner_error {
        width: 100%;
        height: 40vh;
        margin-top: 8rem;
    }

    .error_text {
        font-size: 30px;
        position: absolute;
        top: 220px;
        left: 300px;
    }

    .error_img {
        margin: auto;
    }

    .menubar-header-content {
        position: absolute;
        right: 0px;
    }


}


$breakpoint-tablet: 768px;
@media (max-width: $breakpoint-tablet) {

    // .testimonial-position{
    //     left: 47px !important;
    // }

    .count-circle {
        z-index: 6;
        left: 0px;
        bottom: 0px;
        height: 100%;
    }

    .banner_title {
        font-size: 2rem;
        line-height: 2.5rem;
    }

    .white_banner_title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .banner_image {
        background-image: url('../images/company_banner.webp');
        width: 100%;
        height: 55vh;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .solution_banner {
        background-image: url('../images/solution_banner.png');
        width: 100%;
        height: 55vh;
        object-fit: cover;
        margin-top: 5rem;
    }

    .white_banner {
        background-image: url('../images/white_banner1.webp');
        width: 100%;
        height: 40vh;
        object-fit: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
    }

    .banner_error {
        width: 100%;
        height: 50vh;
        margin-top: 8rem;
    }

    .error_img {
        width: 70%;
        margin-left: -20px;
        margin: auto;
    }

    .error_button {
        position: absolute;
        top: 310x;
        margin-left: -50px;
    }

    .error_text {
        font-size: 30px;
        position: absolute;
        top: 250px;
        left: 200px;
    }

    // .error_button{
    //    margin-top: 100px;
    //    position: absolute;
    //    left: 160px;
    // }
    .banner_h {
        background-color: #000;
        width: 100%;
        max-height: 55vh;
    }

    .service_banner_h {
        background-color: #000;
        width: 100%;
        max-height: 55vh;
    }

    .service_item {
        .service_item_box {
            margin-bottom: 30px;
            padding-left: 30px;

            .service_item_content {
                display: flex;
                gap: 10px;
                align-items: start;
                vertical-align: middle;
            }

            .service_item_content::before {
                width: 24px;
                height: 2px;
            }
        }
    }

    .contact_form_bottom {
        margin-top: 60vh;
    }

    .case_study_img {
        width: 100%;
        height: 15rem;
        object-fit: cover;
    }

    .contact_content_banner {
        background-attachment: fixed;
        overflow: hidden;
        position: relative;
        background-size: cover;
        height: auto;
        display: block;
        padding: 30px 0;
        margin: 0;
    }

    .portfolio {

        .portfolio_card {
            height: 300px;

            .portfolio_title {
                line-height: 1.3;
            }
        }
    }

    .contact_banner {
        padding-bottom: 40px;
    }

    .footer-contact-text {
        max-width: 200px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

    }
}

$breakpoint-mobile: 576px;
@media (max-width: $breakpoint-mobile) {
    .home_banner {
        background-color: #000;
        height: 80vh;
        width: 100%;
        overflow: hidden;
    }

    .portfolio {

        .portfolio_card {
            height: 260px;

            .portfolio_title {
                line-height: 1.5;
            }
        }
    }

    .progress_bar {
        min-width: 150px;
    }

    .progress_bar #length {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 18%;
        background-color: #fff;
        opacity: .2;
    }

    .banner_error {
        width: 100%;
        height: 40vh;
        margin-top: 5rem;
    }

    .error_img {
        width: 70%;
        margin-left: -20px;
        margin: auto;
    }

    .error_button {
        position: absolute;
        top: 105px;
        margin-left: -50px;
    }

    .error_text {
        font-size: 20px;
        position: absolute;
        top: 110px;
        left: 100px;
    }

    .mobile-pagination {
        flex-direction: column-reverse;
    }

    .mobile-pagination-text {
        padding-top: 20px;
    }

    .mob-padd {
        padding-top: 20px;
    }
}

$breakpoint-tablet: 540px;
@media (max-width: $breakpoint-tablet) {

    // .error_text{
    //     font-size: 20px;
    //     position: absolute;
    //     top: 170px;
    //     left: 65px;
    // }
    .banner_error {
        width: 100%;
        height: 50vh;
        margin-top: 8rem;
    }

    .error_img {
        margin: auto;
    }

    .error_text {
        font-size: 20px;
        position: absolute;
        top: 150px;
        left: 150px;
    }

    .error_button {
        position: absolute;
        top: 150px;
        margin-left: -50px;
    }
}

$breakpoint-tablet: 530px;
@media (max-width: $breakpoint-tablet) {

    // .error_text{
    //     font-size: 20px;
    //     position: absolute;
    //     top: 170px;
    //     left: 65px;
    // }
    .banner_error {
        width: 100%;
        height: 40vh;
        margin-top: 8rem;
    }

    .error_img {
        margin: auto;
    }

    .error_text {
        font-size: 20px;
        position: absolute;
        top: 110px;
        left: 90px;
    }

    .error_button {
        position: absolute;
        top: 110px;
        margin-left: -50px;
    }
}



$breakpoint-tablet: 400px;
@media (max-width: $breakpoint-tablet) {
    .testimonial-position{
        left: 20px !important;
    }
    
}

$breakpoint-tablet: 390px;
@media (max-width: $breakpoint-tablet) {
    .testimonial-position{
        left: 47px !important;
    }
    
}

$breakpoint-tablet: 380px;
@media (max-width: $breakpoint-tablet) {
    .testimonial-position{
        left: 42px !important;
    }
    
}

$breakpoint-tablet: 360px;
@media (max-width: $breakpoint-tablet) {
    .testimonial-position{
        left: 35px !important;
    }
    
}


$breakpoint-tablet: 280px;
@media (max-width: $breakpoint-tablet) {
   

    .banner_error {
        width: 100%;
        height: 40vh;
        margin-top: 8rem;
    }

    .error_text {
        font-size: 15px;
        position: absolute;
        top: 60px;
        left: 50px;
    }

    .error_button {
        margin-top: 50px;
    }

    .error_img {
        margin: auto;
    }
}

$breakpoint-tablet: 1024px;
@media (min-width: $breakpoint-tablet) {
    .count-circle {
        position: absolute;
        z-index: 6;
        left: -270px;
        bottom: 40px;
    }
}

$breakpoint-tablet: 1023px;
@media (max-width: $breakpoint-tablet) {
    .count-circle {
        position: absolute;
        z-index: 6;
        left: 0;
        bottom: 40px;
    }
}

$breakpoint-tablet: 1280px;
@media (min-width: $breakpoint-tablet) {
    .count-circle {
        position: absolute;
        z-index: 6;
        left: -150px;
        bottom: 40px;
    }
}

$breakpoint-tablet: 1536px;
@media (min-width: $breakpoint-tablet) {
    .count-circle {
        position: absolute;
        z-index: 6;
        left: -65px;
        bottom: 40px;
    }
}

.slick-prev,
.slick-next {
    margin: 0 20px;
}

// .slick-prev:before,.slick-next:before {
//     color: red;
//   }